/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createService = `mutation CreateService($input: CreateServiceInput!) {
  createService(input: $input) {
    id
    title
    customLink {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    previews {
      type
      value
      description
    }
    amount
    createdAt
    summary
    description
    input {
      type
      key
      label
      placeholder
      required
      description
      defaultValue
      accept
      options {
        value
        label
      }
      selectMultiple
      selectNone
      selectCreate
    }
    archived
    apiType
    inputEndpoint {
      url
      action
    }
    statusEndpoint {
      url
      action
    }
    outputEndpoint {
      url
      action
    }
    sampleResponse {
      type
      key
      label
      description
      exampleValue
    }
    owner
    draft
    plans {
      items {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      nextToken
    }
    stripeId
    seoTitle
    seoDescription
    seoImage
  }
}
`;
export const updateService = `mutation UpdateService($input: UpdateServiceInput!) {
  updateService(input: $input) {
    id
    title
    customLink {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    previews {
      type
      value
      description
    }
    amount
    createdAt
    summary
    description
    input {
      type
      key
      label
      placeholder
      required
      description
      defaultValue
      accept
      options {
        value
        label
      }
      selectMultiple
      selectNone
      selectCreate
    }
    archived
    apiType
    inputEndpoint {
      url
      action
    }
    statusEndpoint {
      url
      action
    }
    outputEndpoint {
      url
      action
    }
    sampleResponse {
      type
      key
      label
      description
      exampleValue
    }
    owner
    draft
    plans {
      items {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      nextToken
    }
    stripeId
    seoTitle
    seoDescription
    seoImage
  }
}
`;
export const deleteService = `mutation DeleteService($input: DeleteServiceInput!) {
  deleteService(input: $input) {
    id
    title
    customLink {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    previews {
      type
      value
      description
    }
    amount
    createdAt
    summary
    description
    input {
      type
      key
      label
      placeholder
      required
      description
      defaultValue
      accept
      options {
        value
        label
      }
      selectMultiple
      selectNone
      selectCreate
    }
    archived
    apiType
    inputEndpoint {
      url
      action
    }
    statusEndpoint {
      url
      action
    }
    outputEndpoint {
      url
      action
    }
    sampleResponse {
      type
      key
      label
      description
      exampleValue
    }
    owner
    draft
    plans {
      items {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      nextToken
    }
    stripeId
    seoTitle
    seoDescription
    seoImage
  }
}
`;
export const createRequisition = `mutation CreateRequisition($input: CreateRequisitionInput!) {
  createRequisition(input: $input) {
    id
    service {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    amount
    input {
      key
      value
      label
      type
    }
    createdAt
    status {
      value
      date
      description
    }
    output {
      key
      value
      label
      type
    }
    outputNote
    canceled
    refunded
    archived
    test
    owner
    vendor
    api
    chargeId
    receiptUrl
    vendorMetadata {
      key
      value
    }
    paymentType
    subscription {
      id
      stripeId
      owner
      plan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      createdAt
      vendor
      onCreateService {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      onUpdateService {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      onDeleteService {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      onCreateRequisition {
        id
        amount
        createdAt
        outputNote
        canceled
        refunded
        archived
        test
        owner
        vendor
        api
        chargeId
        receiptUrl
        paymentType
      }
      onUpdateRequisition {
        id
        amount
        createdAt
        outputNote
        canceled
        refunded
        archived
        test
        owner
        vendor
        api
        chargeId
        receiptUrl
        paymentType
      }
      onDeleteRequisition {
        id
        amount
        createdAt
        outputNote
        canceled
        refunded
        archived
        test
        owner
        vendor
        api
        chargeId
        receiptUrl
        paymentType
      }
      onCreateCustomLink {
        id
        owner
      }
      onUpdateCustomLink {
        id
        owner
      }
      onDeleteCustomLink {
        id
        owner
      }
      onCreatePlan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      onUpdatePlan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      onDeletePlan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
    }
  }
}
`;
export const updateRequisition = `mutation UpdateRequisition($input: UpdateRequisitionInput!) {
  updateRequisition(input: $input) {
    id
    service {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    amount
    input {
      key
      value
      label
      type
    }
    createdAt
    status {
      value
      date
      description
    }
    output {
      key
      value
      label
      type
    }
    outputNote
    canceled
    refunded
    archived
    test
    owner
    vendor
    api
    chargeId
    receiptUrl
    vendorMetadata {
      key
      value
    }
    paymentType
    subscription {
      id
      stripeId
      owner
      plan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      createdAt
      vendor
      onCreateService {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      onUpdateService {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      onDeleteService {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      onCreateRequisition {
        id
        amount
        createdAt
        outputNote
        canceled
        refunded
        archived
        test
        owner
        vendor
        api
        chargeId
        receiptUrl
        paymentType
      }
      onUpdateRequisition {
        id
        amount
        createdAt
        outputNote
        canceled
        refunded
        archived
        test
        owner
        vendor
        api
        chargeId
        receiptUrl
        paymentType
      }
      onDeleteRequisition {
        id
        amount
        createdAt
        outputNote
        canceled
        refunded
        archived
        test
        owner
        vendor
        api
        chargeId
        receiptUrl
        paymentType
      }
      onCreateCustomLink {
        id
        owner
      }
      onUpdateCustomLink {
        id
        owner
      }
      onDeleteCustomLink {
        id
        owner
      }
      onCreatePlan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      onUpdatePlan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      onDeletePlan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
    }
  }
}
`;
export const deleteRequisition = `mutation DeleteRequisition($input: DeleteRequisitionInput!) {
  deleteRequisition(input: $input) {
    id
    service {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    amount
    input {
      key
      value
      label
      type
    }
    createdAt
    status {
      value
      date
      description
    }
    output {
      key
      value
      label
      type
    }
    outputNote
    canceled
    refunded
    archived
    test
    owner
    vendor
    api
    chargeId
    receiptUrl
    vendorMetadata {
      key
      value
    }
    paymentType
    subscription {
      id
      stripeId
      owner
      plan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      createdAt
      vendor
      onCreateService {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      onUpdateService {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      onDeleteService {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      onCreateRequisition {
        id
        amount
        createdAt
        outputNote
        canceled
        refunded
        archived
        test
        owner
        vendor
        api
        chargeId
        receiptUrl
        paymentType
      }
      onUpdateRequisition {
        id
        amount
        createdAt
        outputNote
        canceled
        refunded
        archived
        test
        owner
        vendor
        api
        chargeId
        receiptUrl
        paymentType
      }
      onDeleteRequisition {
        id
        amount
        createdAt
        outputNote
        canceled
        refunded
        archived
        test
        owner
        vendor
        api
        chargeId
        receiptUrl
        paymentType
      }
      onCreateCustomLink {
        id
        owner
      }
      onUpdateCustomLink {
        id
        owner
      }
      onDeleteCustomLink {
        id
        owner
      }
      onCreatePlan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      onUpdatePlan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      onDeletePlan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
    }
  }
}
`;
export const createCustomLink = `mutation CreateCustomLink($input: CreateCustomLinkInput!) {
  createCustomLink(input: $input) {
    id
    service {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    owner
  }
}
`;
export const updateCustomLink = `mutation UpdateCustomLink($input: UpdateCustomLinkInput!) {
  updateCustomLink(input: $input) {
    id
    service {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    owner
  }
}
`;
export const deleteCustomLink = `mutation DeleteCustomLink($input: DeleteCustomLinkInput!) {
  deleteCustomLink(input: $input) {
    id
    service {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    owner
  }
}
`;
export const createSubscription = `mutation CreateSubscription($input: CreateSubscriptionInput!) {
  createSubscription(input: $input) {
    id
    stripeId
    owner
    plan {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
    createdAt
    vendor
    onCreateService {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    onUpdateService {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    onDeleteService {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    onCreateRequisition {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      amount
      input {
        key
        value
        label
        type
      }
      createdAt
      status {
        value
        date
        description
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      api
      chargeId
      receiptUrl
      vendorMetadata {
        key
        value
      }
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
        vendor
      }
    }
    onUpdateRequisition {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      amount
      input {
        key
        value
        label
        type
      }
      createdAt
      status {
        value
        date
        description
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      api
      chargeId
      receiptUrl
      vendorMetadata {
        key
        value
      }
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
        vendor
      }
    }
    onDeleteRequisition {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      amount
      input {
        key
        value
        label
        type
      }
      createdAt
      status {
        value
        date
        description
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      api
      chargeId
      receiptUrl
      vendorMetadata {
        key
        value
      }
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
        vendor
      }
    }
    onCreateCustomLink {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    onUpdateCustomLink {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    onDeleteCustomLink {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    onCreatePlan {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
    onUpdatePlan {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
    onDeletePlan {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
  }
}
`;
export const updateSubscription = `mutation UpdateSubscription($input: UpdateSubscriptionInput!) {
  updateSubscription(input: $input) {
    id
    stripeId
    owner
    plan {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
    createdAt
    vendor
    onCreateService {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    onUpdateService {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    onDeleteService {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    onCreateRequisition {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      amount
      input {
        key
        value
        label
        type
      }
      createdAt
      status {
        value
        date
        description
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      api
      chargeId
      receiptUrl
      vendorMetadata {
        key
        value
      }
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
        vendor
      }
    }
    onUpdateRequisition {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      amount
      input {
        key
        value
        label
        type
      }
      createdAt
      status {
        value
        date
        description
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      api
      chargeId
      receiptUrl
      vendorMetadata {
        key
        value
      }
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
        vendor
      }
    }
    onDeleteRequisition {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      amount
      input {
        key
        value
        label
        type
      }
      createdAt
      status {
        value
        date
        description
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      api
      chargeId
      receiptUrl
      vendorMetadata {
        key
        value
      }
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
        vendor
      }
    }
    onCreateCustomLink {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    onUpdateCustomLink {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    onDeleteCustomLink {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    onCreatePlan {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
    onUpdatePlan {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
    onDeletePlan {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
  }
}
`;
export const deleteSubscription = `mutation DeleteSubscription($input: DeleteSubscriptionInput!) {
  deleteSubscription(input: $input) {
    id
    stripeId
    owner
    plan {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
    createdAt
    vendor
    onCreateService {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    onUpdateService {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    onDeleteService {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    onCreateRequisition {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      amount
      input {
        key
        value
        label
        type
      }
      createdAt
      status {
        value
        date
        description
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      api
      chargeId
      receiptUrl
      vendorMetadata {
        key
        value
      }
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
        vendor
      }
    }
    onUpdateRequisition {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      amount
      input {
        key
        value
        label
        type
      }
      createdAt
      status {
        value
        date
        description
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      api
      chargeId
      receiptUrl
      vendorMetadata {
        key
        value
      }
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
        vendor
      }
    }
    onDeleteRequisition {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      amount
      input {
        key
        value
        label
        type
      }
      createdAt
      status {
        value
        date
        description
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      api
      chargeId
      receiptUrl
      vendorMetadata {
        key
        value
      }
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
        vendor
      }
    }
    onCreateCustomLink {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    onUpdateCustomLink {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    onDeleteCustomLink {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    onCreatePlan {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
    onUpdatePlan {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
    onDeletePlan {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
  }
}
`;
export const createPlan = `mutation CreatePlan($input: CreatePlanInput!) {
  createPlan(input: $input) {
    id
    service {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    stripeId
    name
    allowance
    description
    owner
  }
}
`;
export const updatePlan = `mutation UpdatePlan($input: UpdatePlanInput!) {
  updatePlan(input: $input) {
    id
    service {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    stripeId
    name
    allowance
    description
    owner
  }
}
`;
export const deletePlan = `mutation DeletePlan($input: DeletePlanInput!) {
  deletePlan(input: $input) {
    id
    service {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    stripeId
    name
    allowance
    description
    owner
  }
}
`;
